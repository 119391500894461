import React from "react"
import Img from "gatsby-image/withIEPolyfill"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Metas from "../components/Metas"
import HtmlParser from "../components/HtmlParser"
import PageHeader from "../components/PageHeader"
import OtherArticles from "../components/Blocks/OtherArticles"

const Article = ({ pageContext, data }) => {
  return (
    <Layout contentType={data.node.internal.type}>
      <Metas title={data.node.title} />
      <PageHeader title={data.node.title} />
      <section className="section-bottom mt-12 page-content">
        <div className="container mx-auto px-4">
          {data.node.relationships.image && (
            <div className="block figure text-center mb-12">
              <figure className="relative bg-gray-200 leading-none aspect-w-16 aspect-h-5">
                <Img
                  fluid={
                    data.node.relationships.image.localFile.childImageSharp
                      .fluid
                  }
                  style={{ position: "absolute !important" }}
                  alt={data.node.title}
                  objectFit="contain"
                  objectPosition="50% 50%"
                />
              </figure>
            </div>
          )}

          {data.node.body && (
            <div
              className="block content mb-8 lg:px-24"
              data-typesense-field="body"
            >
              <HtmlParser html={data.node.body.value} />
            </div>
          )}
        </div>
      </section>
      <OtherArticles current={pageContext.internalId} />
    </Layout>
  )
}

export default Article

export const query = graphql`
  query ($internalId: Int!) {
    node: nodeArticle(
      status: { eq: true }
      drupal_internal__nid: { eq: $internalId }
    ) {
      title
      body {
        value
      }
      changed
      internal {
        type
      }
      relationships {
        image: field_image {
          localFile {
            childImageSharp {
              fluid(maxHeight: 470, cropFocus: CENTER, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        tags: field_tags {
          name
          color: field_color
          path {
            alias
          }
        }
      }
    }
  }
`
