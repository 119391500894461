import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import ArticleListItem from "../ListItems/ArticleListItem"

const OtherArticles = ({ current }) => {
  const data = useStaticQuery(graphql`
    {
      otherArticles: allNodeArticle(
        limit: 5
        filter: { status: { eq: true } }
        sort: { fields: created, order: DESC }
      ) {
        nodes {
          nid: drupal_internal__nid
          title
          path {
            alias
          }
          relationships {
            image: field_image {
              localFile {
                childImageSharp {
                  fluid(
                    maxWidth: 332
                    maxHeight: 208
                    cropFocus: CENTER
                    quality: 100
                  ) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            tags: field_tags {
              name
              path {
                alias
              }
            }
          }
        }
      }
    }
  `)

  let otherArticles = data.otherArticles.nodes

  if (data.otherArticles.nodes) {
    otherArticles = data.otherArticles.nodes.filter(({ nid }) => {
      return nid !== current
    })
  }

  if (otherArticles.length > 4) {
    otherArticles = otherArticles.slice(0, 4)
  }

  return otherArticles.length > 0 ? (
    <section className="section-bottom page-content-suggestion">
      <div className="section-top container mx-auto px-4 border-t border-gray-200">
        <h2 className="title h2">
          <span className="block font-normal font-handwritten text-4xl text-gray-dark">
            Vivre à Coucy
          </span>
          <small className="uppercase text-primary">À lire aussi</small>
        </h2>
        <div className="columns grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-8">
          {otherArticles.map((node, index) => (
            <div key={index} className={`column article`}>
              <ArticleListItem content={node} />
            </div>
          ))}
        </div>
      </div>
    </section>
  ) : (
    <></>
  )
}

export default OtherArticles
